import React, { useState, useRef, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Button,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Grid2,
    CircularProgress,
    IconButton,
} from '@mui/material';

import { useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';
import recosoftLogo from '../assets/images/logos/recosoft-logo.svg';
import pdf2officeLogo from '../assets/images/logos/PDF2Office-logo.png';
import removeIcon from '../assets/images/icons/delete.png';

import axios from 'axios';  // Importing axios
// const axios = require('axios');
import ReCAPTCHA from 'react-google-recaptcha';  // Import ReCAPTCHA component

let globalTokenCounter = 1;  // Define a global variable
let savedToken = null;
let lastProcessedConversionType = '';
const CONVERTED_FILES_KEY = 'ConvertedFiles';

const App = () => {
    const [files, setFiles] = useState([]);
    const inputRef = useRef(null); // Initialize the ref

    const [conversionType, setConversionType] = useState('pdf-to-word'); // Set default value to 'pdf-to-word'
    
    // const [status, setStatus] = useState('');
    const [isConverting, setIsConverting] = useState(false); // Track conversion status

    const [sessionStartTime, setSessionStartTime] = useState(null);  // Track when the session starts

    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // Whether CAPTCHA is verified
    const [captchaResponse, setCaptchaResponse] = useState(null);  // Store the captcha response

    useEffect(() => {
        localStorage.removeItem(CONVERTED_FILES_KEY);

    }, []);  // Empty dependency array means this runs once when the component is mounted
    
    const storeConvertedFiles = (token, filename, fileSize, lastModifiedDate) => {
        // Retrieve the existing converted files list from localStorage
        const convertedFiles = JSON.parse(localStorage.getItem(CONVERTED_FILES_KEY)) || [];
    
        // Check if a file with the same filename, size, and last modified date already exists
        const isDuplicate = convertedFiles.some(file => 
            file.filename === filename && 
            file.fileSize === fileSize && 
            file.lastModifiedDate === lastModifiedDate
        );
    
        // If it's not a duplicate, add the new file's details
        if (!isDuplicate) {
            convertedFiles.push({ 
                token, 
                filename, 
                fileSize, 
                lastModifiedDate 
            });
    
            // Save the updated list back to localStorage
            localStorage.setItem(CONVERTED_FILES_KEY, JSON.stringify(convertedFiles));
        } else {
            console.log("This file is already converted and stored.");
        }
    };
    
    // Retrieve the converted files (filenames and tokens) from localStorage
    const getConvertedFiles = () => {
        return JSON.parse(localStorage.getItem(CONVERTED_FILES_KEY)) || [];
    };
    //// today changes - 19/11/2024

    const incrementGlobalTokenCounter = () => {
        globalTokenCounter += 1;
    };

    // Function to handle the captcha verification
    const handleCaptchaChange = (value) => {
        setCaptchaResponse(value);
    };


    // Reset session if expired or user clicks start conversion after session expiry
    const resetSession = () => {
        savedToken = null;
        globalTokenCounter = 1;
        setSessionStartTime(null);
        setCaptchaResponse(null);
        setIsCaptchaVerified(false);

        // Clear the CONVERTED_FILES_KEY from localStorage
        localStorage.removeItem(CONVERTED_FILES_KEY);
    };

    // Function to show the collected alerts
    const showAlerts = (messages) => {
        if (messages.length > 0) {
            alert(messages.join('\n'));  // Combine messages into one string with new lines and show the alert
        }
    };
     
    const onDrop = async (acceptedFiles) => {
        const invalidFiles = [];  // Array to collect invalid files
        const validFiles = [];    // Array to collect valid files
        const alertMessages = []; // Array to collect all alert messages

        // Step 1: Check each file to see if it's a valid PDF file
        for (const file of acceptedFiles) {
            const isPdf = await isPdfFile(file);  // Wait for the PDF check to complete
    
            if (!isPdf) {
                invalidFiles.push(file);  // Add invalid file to invalidFiles array
            } else {
                validFiles.push(file);    // Add valid file to validFiles array
            }
        }
    
        // If there are invalid files, show an alert
        if (invalidFiles.length > 0) {
            if (validFiles.length > 0) {
                alertMessages.push('Some files are not valid PDF files!');
            }
            else {
                alertMessages.push('This is not a valid PDF file!');
            }
        }

        // Step 1: Filter files that exceed 5MB
        const largeFiles = validFiles.filter(file => file.size > 5 * 1024 * 1024); // 5MB in bytes
        const filesUnder5MB = validFiles.filter(file => file.size <= 5 * 1024 * 1024); // Valid files under 5MB
    
        // Step 2: Show an alert if there are any files that are too large
        if (largeFiles.length > 0) {
            alertMessages.push('Some files exceed the maximum size of 5 MB.');
        }

        if (filesUnder5MB.length === 0) {
            if (alertMessages.length > 0) {
                showAlerts(alertMessages);  // Show alerts at the end
            }
            return;
        }
    
        // Step 3: Filter out files that are already added based on file name
        // const newFiles = validFiles.filter(file => {
        //     return !files.some(existingFile => existingFile.file.name === file.name);
        // });

        // Step 3: Filter out files that are already added based on file name, size, and last modified date
        const newFiles = filesUnder5MB.filter(file => {
            return !files.some(existingFile => {
                return (
                    existingFile.file.name === file.name &&               // Compare file name
                    existingFile.file.size === file.size &&               // Compare file size
                    existingFile.file.lastModified === file.lastModified  // Compare last modified date
                );
            });
        });
        
        if (newFiles.length === 0) {
            alertMessages.push('One or more files have already been added.');
            if (alertMessages.length > 0) {
                showAlerts(alertMessages);  // Show alerts at the end
            }
            return;
        }
    
        // Step 4: Filter out files that are NOT "Waiting" (i.e., remove completed or processing files)
        const filesToKeep = files.filter(fileObj => fileObj.status === 'Waiting');
        
        // Step 5: Count only files with status "Waiting" to check the limit
        const currentFileCount = filesToKeep.length;
    
        // Step 6: Add new files only if the total count does not exceed the limit (5 files)
        if (currentFileCount + newFiles.length <= 5) {
            setFiles([
                ...filesToKeep,  // Keep only the files that are still "Waiting"
                ...newFiles.map(file => ({ file, status: 'Waiting' })),
            ]);
        } else {
            alertMessages.push('You can only upload a maximum of 5 files.');
        }

        if (alertMessages.length > 0) {
            showAlerts(alertMessages);  // Show alerts at the end
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        }
        // maxSize: 5 * 1024 * 1024, // 5 MB
    });

    const isPdfFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onloadend = () => {
            const uint = new Uint8Array(reader.result);
            // Check for the PDF file signature (%PDF-1.x)
            const isPdf = uint[0] === 0x25 && uint[1] === 0x50 && uint[2] === 0x44 && uint[3] === 0x46;
            resolve(isPdf);
          };
    
          reader.onerror = () => {
            reject('Error reading file');
          };
    
          // Read first 4 bytes (for PDF signature check)
          reader.readAsArrayBuffer(file.slice(0, 4));
        });
    };

    
    const handleRemove = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    // const handleRemove = (index) => {
    //     // First, remove the file from the local state
    //     const updatedFiles = files.filter((_, i) => i !== index);
    
    //     // Assuming the filename and token are stored in `convertedFiles` in localStorage
    //     const storedConvertedFiles = JSON.parse(localStorage.getItem(CONVERTED_FILES_KEY)) || [];
    
    //     // Get the file to be deleted
    //     const deletedFile = files[index];
    
    //     // Remove the deleted file entry from localStorage based on the filename or token
    //     const updatedConvertedFiles = storedConvertedFiles.filter(
    //         (file) => file.filename !== deletedFile.file.name // You can also filter by token if needed
    //     );
    
    //     // Save the updated list back to localStorage
    //     localStorage.setItem(CONVERTED_FILES_KEY, JSON.stringify(updatedConvertedFiles));
    
    //     // Update the state with the new list of files
    //     setFiles(updatedFiles);
    // };


    const handleConversionTypeChange = (event) => {
        setConversionType(event.target.value);
    };

      // Function to set all file statuses to "Waiting"
    const setAllFilesToWaiting = () => {
        const updatedFiles = files.map(file => ({
        ...file,
        status: 'Waiting',
        }));
        setFiles(updatedFiles);
    };

    // Handle the Start Conversion button click
    const uploadFiles = async () => {
        if (!files.length) {
            alert('Please select files.');
            return;
        }

            // Check if there are any files with status "Waiting"
        const waitingFiles = files.filter(fileObj => fileObj.status === 'Waiting');

        // if (waitingFiles.length === 0) {
        //     // If no files are "Waiting", show an alert to add new files
        //     alert('Files are already processed. Please add new files for conversion.');
        //     return;
        // }

        if(lastProcessedConversionType === '')
        {
            lastProcessedConversionType = conversionType;
        }

        if (waitingFiles.length === 0) {
            // If no files are "Waiting", check if conversion type has changed
            if (lastProcessedConversionType !== conversionType) {
                // If conversion type has changed, allow processing to proceed
            } else {
                // If the conversion type hasn't changed, return without processing
                alert('Files are already processed. Please add new files for conversion.');
                return;
            }
        }

        setAllFilesToWaiting();

        // if (!conversionType) {
        //     alert('Please select conversion type.');
        //     return;
        // }

        // Check if the session has expired
        if (sessionStartTime) {
            const currentTime = new Date().getTime();  // Get current time
            const sessionDuration = 10 * 60 * 1000;  // 10 minutes in milliseconds

            // If the difference between current time and session start time is more than 10 minutes
            if (currentTime - sessionStartTime > sessionDuration) {
                resetSession();  // Reset session
                alert('Session is expired.');
                return;  // Stop further processing
            }
        }

        if (!savedToken) {
            if(!isCaptchaVerified)
            {
                if (!captchaResponse) {
                    alert('Please complete the CAPTCHA!');
                    return;
                }
            }
            
            const newToken = await getToken();  // Get token only if not present
            if (!newToken) return;
            savedToken = newToken
            // setToken(newToken);
            setSessionStartTime(new Date().getTime());  // Set session start time
        }

        setIsConverting(true); // Disable buttons during conversion

        // Loop through the files sequentially
        for (let i = 0; i < files.length; i++) {
            // let fileToken = `${token}~${globalToken}`;

            let fileToken = savedToken.replace(/~\d+$/, `~${globalTokenCounter}`);

            const file = files[i].file;

            try {
                //// today changes - 19/11/2024
                // Step 1: Upload the file
                const uploadSuccess = await processFileUpload(file, fileToken, i);
                if (!uploadSuccess) {
                    // setStatus(`Upload failed for ${file.name}`);
                    continue;
                }
                else
                {
                    const filename = file.name;
                    const fileSize = file.size;
                    const lastModifiedDate = file.lastModified;
                    storeConvertedFiles(fileToken, filename, fileSize, lastModifiedDate); // Save to localStorage
                }

                // Step 2: Track progress for the uploaded file
                const progressSuccess = await trackProgress(fileToken, i);
                if (!progressSuccess) {
                    // setStatus(`Conversion failed for ${file.name}`);
                    continue;
                }

                // Step 3: Download the file once the conversion is complete
                // await downloadFiles(fileToken);
                await downloadFiles(fileToken, i);
            } catch (error) {
                console.error('Error in file processing:', error);
            }

            incrementGlobalTokenCounter();
        }

        lastProcessedConversionType = conversionType;
        setIsConverting(false); // Enable buttons after conversion is completed
    };


    const getToken = async () => {
        const newUUID = uuidv1();
        const clientId = newUUID;

        try {
            //const response = await axios.post('http://localhost:8000/api/getToken.php', {
            const response = await axios.post('https://portal-test.pdf2office.com/Converter/api/getToken.php', {
                clientId: clientId,
                recaptchaResponse: captchaResponse  // Send CAPTCHA response with UUID
            }, {
                timeout: 60000 // Timeout after 60 seconds
            });

            const data = response.data;

            if (data && data.token) {
                setIsCaptchaVerified(true); // CAPTCHA is verified successfully
                setCaptchaResponse(null); 
                return data.token; // Return the token if 
            } else {
                // setStatus('Token not found in response.');
                alert('Failed to process the request. Please try again.');
                return null;
            }
        } catch (error) {
            alert('Failed to process the request. Please try again.');
            // setStatus('Failed to retrieve token: ' + error.message);
        }
    };


    const processFileUpload = async (file, fileToken, index) => {
        const formData = new FormData();

        //// If file previously send then send filename
        const convertedFiles = getConvertedFiles(); // Get the list of already converted files

        const filename = file.name;
        const fileSize = file.size;
        const lastModified = file.lastModified;

        // Check if the file has already been converted (by filename, size, and metadata)
        const existingConvertedFile = convertedFiles.find(
            (convertedFile) =>
                convertedFile.filename === filename &&
                convertedFile.fileSize === fileSize &&
                convertedFile.lastModifiedDate === lastModified
        );

        if (existingConvertedFile) {
            // If the file was already converted, check if there are other files with the same name
            const duplicateFile = convertedFiles.filter(
                (convertedFile) => convertedFile.filename === filename
            );

            if (duplicateFile.length > 1) {
                // If other files with the same name exist, append the file itself
                formData.append('file', file);
            } else {
                const existingToken = existingConvertedFile.token;
                let lastTildeIndex = existingToken.lastIndexOf('~');
                const existingTokenWithoutIndex = existingToken.slice(0, lastTildeIndex);

                lastTildeIndex = fileToken.lastIndexOf('~');
                const currentTokenWithoutIndex = fileToken.slice(0, lastTildeIndex);

                if (existingTokenWithoutIndex === currentTokenWithoutIndex) {
                    formData.append('filename', filename);
                }
                else
                {
                    formData.append('file', file);
                }
            }
        } else {
            // If the file does not exist, append the actual file
            formData.append('file', file);
        }
        ////

        // formData.append('file', file);
        formData.append('conversionType', conversionType); // Add the conversion type to the request
    
        try {
            setFileStatus(index, 'Uploading...');
    
            // Make the POST request with Axios
            //const response = await axios.post('http://localhost:8000/api/uploadFile.php', formData, {
            const response = await axios.post('https://portal-test.pdf2office.com/Converter/api/uploadFile.php', formData, {
                headers: {
                    'Authorization': `Bearer ${fileToken}`,
                    'Content-Type': 'multipart/form-data', // Explicitly set Content-Type
                },
                timeout: 120000, // Set timeout to 120 seconds (120,000 milliseconds)
            });
    
            // Handle the response
            const result = response.data;
    
            if (result.status && result.status.toLowerCase() === 'success') {
                setFileStatus(index, 'Converting...');
                return true; // Return true if upload was successful
            }
            else if (result.status && result.status.toLowerCase() === 'failed') {
                const statusMessage = `Failed (${result.error})`;
                setFileStatus(index, statusMessage);
                return false; // Return false if the upload failed
            } else {
                setFileStatus(index, 'Upload Failed');
                return false; // Return true if upload was successful
            }
        } catch (error) {
            console.error('Upload error:', error);
    
            // Handle error cases
            // if (error.response) {
            //     // Server responded with a status code outside 2xx
            //     setFileStatus(index, 'Upload Failed');
            // } else if (error.request) {
            //     // No response was received
            //     setFileStatus(index, 'No response from server');
            // } else {
            //     // Something went wrong in setting up the request
            //     setFileStatus(index, 'Upload Failed');
            // }
    
            setFileStatus(index, 'Upload Failed');
            return false; // Return false if the upload failed
        }
    };

    const trackProgress = async (token, index) => {
        return new Promise((resolve, reject) => {
            const checkProgress = async () => {
                try {
                    // Fetch the conversion status using Axios
                    //const response = await axios.get('http://localhost:8000/api/getConversionStatus.php', {
                    const response = await axios.get('https://portal-test.pdf2office.com/Converter/api/getConversionStatus.php', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    const { status: conversionStatus, error: conversionError } = response.data;
    
                    if (conversionStatus.toLowerCase() === 'completed') {
                        // setFileStatus(index, 'Completed');
                        setFileStatus(index, 'Downloading...');
                        resolve(true);  // Resolve the promise when conversion is completed
                    } else if (conversionStatus.toLowerCase() === 'failed') {
                        const statusMessage = `Failed (${conversionError ? conversionError : 'Unknown error'})`;
                        setFileStatus(index, statusMessage);
                        reject(new Error('Failed'));
                    } else {
                        // If still in progress, wait for 1.5 seconds and try again
                        setTimeout(checkProgress, 1500);  // Recursively call checkProgress after 1.5 seconds
                    }
                } catch (error) {
                    console.error('Error fetching progress:', error);
                    setFileStatus(index, 'Progress Error');
                    reject(new Error('Error during progress check.'));
                }
            };
    
            // Start the first progress check
            checkProgress();
        });
    };


    const downloadFiles = async (token, index) => {  // Pass index as parameter for correct context
        try {
            // Make the GET request using Axios with timeout
            //const response = await axios.get('http://localhost:8000/api/new_downloadFile.php', {
            const response = await axios.get('https://portal-test.pdf2office.com/Converter/api/downloadFile.php', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob',  // Ensure the response is treated as a Blob (binary data)
                timeout: 120000  // Timeout set to 120,000 milliseconds (120 seconds)
            });
    
            // Check for a successful HTTP response (status code 200)
            if (response.status === 200) {
                // Get the filename from the Content-Disposition header
                // Get the filename from the Content-Disposition header
                const disposition = response.headers.get('Content-Disposition');
                let filename = 'downloaded_file'; // Default filename

                if (disposition && disposition.includes('filename=')) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, ''); // Clean quotes
                    }
                }

                // Get the blob from the response
                // const blob = await response.blob();
                
                // // Create a URL for the blob
                // const url = window.URL.createObjectURL(blob);

                const url = window.URL.createObjectURL(response.data);
                
                // Create a temporary anchor element to trigger the download
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename; // Use the extracted filename
        
                // Append the anchor to the body and trigger the download
                document.body.appendChild(a);
                a.click();

                setFileStatus(index, 'Completed');

                // Clean up: remove the anchor and revoke the object URL
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                // If response is not 200, update status to failed
                setFileStatus(index, 'Downloading failed');
                console.error(`Download failed with status code: ${response.status}`);
            }
        } catch (error) {
            // Handle different error types
            setFileStatus(index, 'Downloading failed');  // Set status to failed
    
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out');
            } else {
                console.error('Error downloading file:', error);
            }
        }
    };
    

    // Helper function to set the status of a file
    const setFileStatus = (index, status) => {
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].status = status; // Update the status for the file
            return updatedFiles;
        });
    };

    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="static" style={{ background: '#f1f1f1' }}>
                <Toolbar>
                    <img src={recosoftLogo} alt="Company Logo" style={{ height: '40px', marginRight: '10px' }} />
                </Toolbar>
            </AppBar>

            <Container style={{ flexGrow: 1 }}>
                <Box textAlign="center" marginY={4}>
                    <img src={pdf2officeLogo} alt="App Logo" />
                    <Typography variant="h5">Convert PDF to Office</Typography>
                </Box>

                <Grid2 container spacing={2} justifyContent="center" alignItems="center">
                    <Grid2 item>
                        <Typography variant="subtitle1">Conversion type:</Typography>
                    </Grid2>
                    <Grid2 item>
                        <Select
                            value={conversionType}  // Use state value for selected option
                            onChange={handleConversionTypeChange}
                            disabled={isConverting}  // Disable the dropdown if isConverting is true
                            displayEmpty
                            sx={{
                                height: 40,  // Adjust the height of the select button
                                fontSize: '16px',  // Optional: Adjust font size for better fit
                                padding: '0 12px',  // Optional: Adjust padding to make it look better
                            }}
                        >
                            <MenuItem value="pdf-to-word">Microsoft Word</MenuItem>
                            <MenuItem value="pdf-to-excel">Microsoft Excel</MenuItem>
                            <MenuItem value="pdf-to-ppt">Microsoft PowerPoint</MenuItem>
                            <MenuItem value="pdf-to-text">Text</MenuItem>
                            <MenuItem value="pdf-to-images">Extract Images</MenuItem>
                        </Select>
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12}>
                    <div
                        {...getRootProps({
                            className: 'dropzone',
                            style: { 
                                border: '2px dashed #ccc', 
                                padding: '20px', 
                                marginTop: '20px', 
                                textAlign: 'center',
                                opacity: isConverting ? 0.5 : 1,  // Dim the area when converting
                                pointerEvents: isConverting ? 'none' : 'auto',  // Disable interaction when converting
                            }
                        })}
                    >
                        <input {...getInputProps()} />
                        <Typography 
                            variant="body1" 
                            sx={{ marginBottom: 2 }}
                        >
                            Drag and drop a PDF file to use our PDF to Office converter. (Maximum 5 files and maximum file size allowed is 5 MB)
                        </Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => inputRef.current && inputRef.current.click()} 
                            sx={{
                                height: 40, 
                                fontSize: '16px',
                                padding: '0 25px',
                            }}
                            disabled={isConverting}  // Disable the button when converting
                        >
                            Select Files
                        </Button>
                    </div>
                </Grid2>

                {/* Conditionally render the table and the Start Conversion button */}
                {files.length > 0 && (
                    <>
                        <TableContainer component={Paper} sx={{ width: '100%', marginTop: '30px' }}>
                            <Table sx={{ borderCollapse: 'collapse' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '50px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Sr</TableCell>
                                        <TableCell sx={{ width: '500px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>File Name</TableCell>
                                        <TableCell sx={{ width: '120px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Size (MB)</TableCell>
                                        <TableCell sx={{ width: '150px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Status</TableCell>
                                        <TableCell sx={{ width: '50px', height: '15px', padding: '10px', verticalAlign: 'middle', textAlign: 'center' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {files.map((fileObj, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: '50px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ width: '500px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>{fileObj.file.name}</TableCell>
                                            <TableCell sx={{ width: '120px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>
                                                {(fileObj.file.size / 1024 / 1024).toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                width: '150px',
                                                height: '10px',
                                                padding: '8px',
                                                verticalAlign: 'middle',
                                                color: fileObj.status.toLowerCase().includes('failed') ? 'red' : 'inherit', // Apply red color if status contains 'failed'
                                                }}
                                            >
                                                {fileObj.status}
                                                {(fileObj.status.toLowerCase().includes('uploading') || fileObj.status.toLowerCase().includes('converting')) && (
                                                <CircularProgress size={24} style={{ marginLeft: 10 }} />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                width: '50px',
                                                height: '10px',
                                                padding: '8px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center', // Center horizontally
                                                lineHeight: 'normal', // Ensure vertical alignment
                                                }}
                                            >
                                                <IconButton
                                                onClick={() => handleRemove(index)}
                                                aria-label="remove file"
                                                style={{
                                                    padding: '0', // Remove padding to only show the icon
                                                    backgroundColor: isConverting ? '#f1f1f1' : 'transparent', // Change background color when disabled
                                                    cursor: isConverting ? 'not-allowed' : 'pointer', // Change cursor to 'not-allowed' when disabled
                                                }}
                                                disabled={isConverting}
                                                >
                                                <img
                                                    src={removeIcon} // Image path for the remove icon
                                                    alt="Remove"
                                                    style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    opacity: isConverting ? 0.5 : 1, // Change opacity when disabled
                                                    filter: isConverting ? 'grayscale(100%)' : 'none', // Optionally grayscale the icon when disabled
                                                    }}
                                                />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box textAlign="center" marginY={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={uploadFiles}
                                disabled={!conversionType || !files.length || isConverting}
                                sx={{
                                    height: 40,  // Set the same height as the Select component
                                    fontSize: '16px',  // Set the same font size as the Select component
                                    padding: '0 25px',  // Match the padding of the Select button
                                }}
                            >
                                Start Conversion
                            </Button>
                        </Box>      
                    </>
                )}

                {!isCaptchaVerified && (
                    <Box textAlign="center" marginY={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ReCAPTCHA
                            sitekey="6LfmySUqAAAAAK55uWzlBenZ-kYq9CQGRQ5FtCwc"  // Replace with your reCAPTCHA site key
                            onChange={handleCaptchaChange}
                        />
                    </Box>
                )}   

                {/* {status && <Typography variant="body2" style={{ marginTop: '20px' }}>{status}</Typography>} */}
            </Container>

            <footer style={{ background: '#f1f1f1', padding: '10px', textAlign: 'center' }}>
                <Typography variant="body2">© Recosoft Corporation 2024</Typography>
            </footer>
        </div>
    );
};

export default App;
